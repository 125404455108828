
import { Prop, Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import DropdownPlaceholder from "@/components/tasqsCommon/DropdownPlaceholder.vue";
import workflowModule from "@/store/modules/workflowModule";

@Component({
  components: {
    DropdownPlaceholder,
  },
})
export default class NotificationHeader extends mixins() {
  notificationSettings: any = [];

  workStartHour: any = null;

  workEndHour: any = null;

  isDataLoading = true;

  async created() {
    const { notification_settings }: any = await workflowModule.getUserDetails({
      username: workflowModule.user.email.toLowerCase(),
    });

    if (notification_settings !== null) {
      const settings = JSON.parse(notification_settings);
      console.log(settings);
      if (settings["AllowedEvents"]) {
        this.notificationSettings = settings["AllowedEvents"];
        if (this.notificationSettings) {
          this.setSettingsCheckValues();
        }

        console.log(settings);
        this.workStartHour = settings["WorkHourStartTime"];
        this.workEndHour = settings["WorkHourEndTime"];
      }
    }
    this.isDataLoading = false;
  }
  // get checkedValue() {
  //   return [
  //     'NEW_OFF_TARGET_TASQ_ASSIGNED_TO_ME',
  //     'NEW_MANUAL_TASQ_TO_ME',
  //     'TASQ_REASSIGNED_TO_ME',
  //     'OFF_TARGET_TASQ_ASSIGNED_TO_ME_RETURN_TO_NORMAL',
  //   ];
  // }
  settings = [
    {
      text: "A Tasq new is assigned to me",
      value: "NEW_TASQ_ASSIGNED_TO_ME",
      checked: true,
    },
    { text: "A Tasq is reassigned to me", value: "TASQ_REASSIGNED_TO_ME", checked: true },
    {
      text: "An Off-Target Tasq assigned to me returns to target",
      value: "OFF_TARGET_TASQ_ASSIGNED_TO_ME_RETURN_TO_NORMAL",
      checked: true,
    },
  ];

  async saveSettings() {
    await workflowModule.postUserNotificationSettings({
      payload: this.settings.filter((s) => s.checked).map((v) => v.value),
      workStartHour: this.workStartHour,
      workEndHour: this.workEndHour,
    });
  }

  setSettingsCheckValues() {
    this.settings = this.settings.map((setting) => {
      if (this.notificationSettings.includes(setting.value)) {
        setting.checked = true;
      } else {
        setting.checked = false;
      }
      return setting;
    });
  }
}
